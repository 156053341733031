import { ClassData } from '@models/class-data';
import { SpaceDiscountType } from './enums/space-discount-type.enum';

export class SpaceDiscount {
  id: number;

  percentage: number;
  type: SpaceDiscountType;

  constructor(json: ClassData<SpaceDiscount>) {
    this.id = +json.id;

    this.percentage = +json.percentage;
    this.type = json.type;
  }
}
