import { ClassData } from '@models/class-data';

export class Address {
  id?: number;

  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;
  country?: string;
  federalState?: string;

  lat: number;
  lng: number;

  placeId?: string;

  constructor(json: ClassData<Address>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;
    this.federalState = json.federalState;

    this.lat = +json.lat;
    this.lng = +json.lng;

    this.placeId = json.placeId;
  }

  isFilled(): boolean {
    return !!this.street && !!this.postalCode && !!this.locality;
  }

  isPartiallyFilled(): boolean {
    return !!this.postalCode && !!this.locality && this.hasLatLng();
  }

  hasLatLng(): boolean {
    return !!this.lat && !!this.lng;
  }

  toString(addHTMLLineBreakBeforePostalCode?: boolean): string {
    let address = this.getStreet();

    if (address !== '') {
      if (addHTMLLineBreakBeforePostalCode) {
        address += ',<br/> ';
      } else {
        address += ', ';
      }

      return `${address}${this.postalCode} ${this.locality}`;
    }

    return `${this.postalCode} ${this.locality}`;
  }

  toStringWithName(): string {
    let street = this.getStreet();

    if (street !== '') {
      return `${street}, ${this.locality}`;
    }
    return this.locality;
  }

  toStringWithNameWHtmlLineBreak(): string {
    let street = this.getStreet();

    if (street !== '') {
      return `${street}<br/>${this.postalCode} ${this.locality}`;
    }
    return `${this.postalCode} ${this.locality}`;
  }

  toDisplayForEventPrimary(): string {
    if (this.street && this.street !== '') {
      if (this.streetNumber != '') {
        return `${this.street} ${this.streetNumber}`;
      }
      return this.street;
    }

    return this.locality;
  }

  toDisplayForEventSecondary(): string {
    return this.locality;
  }

  toDisplayForEventCommunityCard(): string {
    return this.locality;
  }

  latLng(): string {
    return `${this.lat}, ${this.lng}`;
  }

  getStreet(): string {
    let street = '';
    if (this.street && this.street !== '') {
      if (this.streetNumber !== '') {
        street = `${this.street} ${this.streetNumber}`;
      } else {
        street = this.street;
      }
    }

    return street;
  }

  getGoogleMapsLink() {
    if (this.placeId && this.placeId !== '' && this.placeId.startsWith('Ch')) {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        this.toStringWithName(),
      )}&query_place_id=${this.placeId}`;
    } else if (this.lat && this.lng) {
      return `https://www.google.com/maps/search/?api=1&query=${this.lat},${this.lng}`;
    } else {
      return '';
    }
  }
}
