import { ClassData } from '@models/class-data';

export class TicketSeatAssignment {
  id: number;

  section: string;
  row: string;
  seatNumber: string;

  constructor(json: ClassData<TicketSeatAssignment>) {
    this.id = json.id;

    this.section = json.section;
    this.row = json.row;
    this.seatNumber = json.seatNumber;
  }

  toString(): string {
    return [this.section, this.row, this.seatNumber]
      .filter((part) => part && part.trim() !== '')
      .join('-');
  }
}
