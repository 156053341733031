import { ClassData } from '@models/class-data';
import moment from 'moment-timezone';
import { SpaceSlotType } from './enums/space-slot-type.enum';
import { SpaceBooking } from './space-booking.model';
import { SpaceCancellationPolicy } from './enums/space-cancellation-policy.enum';
import { Space } from './space.model';

export class SpaceSlot {
  id: number;

  spaceId: number;
  space?: Space;

  type: SpaceSlotType;

  booking?: SpaceBooking;

  name: string;

  startDate: Date;
  endDate: Date;
  allDay: boolean;

  maintenanceTimeBeforeBookingInMinutes: number;
  maintenanceTimeAfterBookingInMinutes: number;

  hourlyRatePriceInCents?: number;
  capacity?: number;

  cancellationPolicy: SpaceCancellationPolicy;

  constructor(json: ClassData<SpaceSlot>) {
    this.id = +json.id;

    this.spaceId = +json.spaceId;
    if (json.space) {
      this.space = new Space(json.space);
    }

    this.type = json.type;

    if (json.booking) {
      this.booking = new SpaceBooking(json.booking);
    }

    this.name = json.name;

    this.startDate = new Date(json.startDate);
    this.endDate = new Date(json.endDate);
    this.allDay = json.allDay;

    this.maintenanceTimeBeforeBookingInMinutes =
      json.maintenanceTimeBeforeBookingInMinutes;
    this.maintenanceTimeAfterBookingInMinutes =
      json.maintenanceTimeAfterBookingInMinutes;

    if (json.hourlyRatePriceInCents) {
      this.hourlyRatePriceInCents = +json.hourlyRatePriceInCents;
    }
    if (json.capacity) {
      this.capacity = +json.capacity;
    }

    this.cancellationPolicy = json.cancellationPolicy;
  }

  getSlotName(): string {
    if (this.booking) {
      return this.booking.name;
    }

    if (this.name !== '') {
      return this.name;
    }

    return SpaceSlotType.getDisplayName(this.type);
  }

  getSpaceSlotColor(): string {
    switch (this.type) {
      case SpaceSlotType.BOOKING:
        return 'var(--brand-primary)';
      case SpaceSlotType.AVAILABILITY:
        return 'var(--brand-secondary)';
      case SpaceSlotType.BLOCKER:
        return 'var(--extra-red)';
      case SpaceSlotType.MAINTENANCE:
        return 'var(--text-tertiary)';
    }
  }

  getDateDayNumber(): string {
    return moment(this.startDate).format('D');
  }

  getDateMonthAndDayFormatted(): string {
    return moment(this.startDate).format('MMM YYYY, ddd');
  }

  getTimeRangeFormatted(): string {
    if (this.allDay) {
      return 'APP.SPACES.BOOKINGS.ALL_DAY';
    }
    return (
      moment(this.startDate).format('HH:mm') +
      '-' +
      moment(this.endDate).format('HH:mm')
    );
  }

  getMaintenanceTimeBeforeSlot(): SpaceSlot | undefined {
    if (this.maintenanceTimeBeforeBookingInMinutes === 0) {
      return undefined;
    }

    const maintenanceStartDate = moment(this.startDate)
      .subtract(this.maintenanceTimeBeforeBookingInMinutes, 'minutes')
      .toDate();

    return new SpaceSlot({
      id: 0,

      spaceId: this.spaceId,
      space: this.space,

      type: SpaceSlotType.MAINTENANCE,

      name: '',

      startDate: maintenanceStartDate,
      endDate: this.startDate,
      allDay: false,

      maintenanceTimeBeforeBookingInMinutes: 0,
      maintenanceTimeAfterBookingInMinutes: 0,

      cancellationPolicy: this.cancellationPolicy,
    });
  }

  getMaintenanceTimeAfterSlot(): SpaceSlot | undefined {
    if (this.maintenanceTimeAfterBookingInMinutes === 0) {
      return undefined;
    }

    const maintenanceEndDate = moment(this.endDate)
      .add(this.maintenanceTimeAfterBookingInMinutes, 'minutes')
      .toDate();

    return new SpaceSlot({
      id: 0,

      spaceId: this.spaceId,
      space: this.space,

      type: SpaceSlotType.MAINTENANCE,

      name: '',

      startDate: this.endDate,
      endDate: maintenanceEndDate,
      allDay: false,

      maintenanceTimeBeforeBookingInMinutes: 0,
      maintenanceTimeAfterBookingInMinutes: 0,

      cancellationPolicy: this.cancellationPolicy,
    });
  }

  isMaintenance(): boolean {
    return this.type === SpaceSlotType.MAINTENANCE;
  }

  getHourlyRatePriceFormatted(): string {
    if (this.hourlyRatePriceInCents === undefined) {
      return '';
    }
    return (this.hourlyRatePriceInCents / 100.0).toFixed(2);
  }
}
