import { ClassData } from '@models/class-data';
import { SpaceFeatureType } from './enums/space-feature-type.enum';

export class SpaceFeature {
  id: number;

  type: SpaceFeatureType;

  icon: string;
  name: string;

  constructor(json: ClassData<SpaceFeature>) {
    this.id = +json.id;

    this.type = json.type;

    this.icon = json.icon;
    this.name = json.name;
  }

  getTitle(): string {
    return `APP.SPACES.FEATURES.${this.name}`;
  }
}
