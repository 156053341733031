import { MultiFilterSelectItem } from '@modules/shared/components/multi-filter-select/multi-filter-select.component';

export enum SpaceBookingStatus {
  DRAFT = 'DRAFT',
  PENDING_HOST_CONFIRMATION = 'PENDING_HOST_CONFIRMATION',
  PENDING_REQUEST = 'PENDING_REQUEST',
  CONFIRMED = 'CONFIRMED',
  DECLINED_HOST = 'DECLINED_HOST',
  DECLINED_OWNER = 'DECLINED_OWNER',
  CANCELLED_OWNER = 'CANCELLED_OWNER',
}

export namespace SpaceBookingStatus {
  export function getAll(): SpaceBookingStatus[] {
    return [
      SpaceBookingStatus.DRAFT,
      SpaceBookingStatus.PENDING_HOST_CONFIRMATION,
      SpaceBookingStatus.PENDING_REQUEST,
      SpaceBookingStatus.CONFIRMED,
      SpaceBookingStatus.DECLINED_HOST,
      SpaceBookingStatus.DECLINED_OWNER,
      SpaceBookingStatus.CANCELLED_OWNER,
    ];
  }

  export function getDisplayName(status?: SpaceBookingStatus): string {
    switch (status) {
      case SpaceBookingStatus.DRAFT:
        return 'APP.SPACES.BOOKINGS.STATUS.DRAFT';
      case SpaceBookingStatus.PENDING_HOST_CONFIRMATION:
        return 'APP.SPACES.BOOKINGS.STATUS.PENDING_HOST_CONFIRMATION';
      case SpaceBookingStatus.PENDING_REQUEST:
        return 'APP.SPACES.BOOKINGS.STATUS.PENDING_REQUEST';
      case SpaceBookingStatus.CONFIRMED:
        return 'APP.SPACES.BOOKINGS.STATUS.CONFIRMED';
      case SpaceBookingStatus.DECLINED_HOST:
        return 'APP.SPACES.BOOKINGS.STATUS.DECLINED_HOST';
      case SpaceBookingStatus.DECLINED_OWNER:
        return 'APP.SPACES.BOOKINGS.STATUS.DECLINED_OWNER';
      case SpaceBookingStatus.CANCELLED_OWNER:
        return 'APP.SPACES.BOOKINGS.STATUS.CANCELLED_OWNER';
      default:
        return '';
    }
  }

  export function getForFilter(): MultiFilterSelectItem[] {
    return [
      {
        value: SpaceBookingStatus.DRAFT,
        label: 'APP.SPACES.BOOKINGS.STATUS.DRAFT',
      },
      {
        value: SpaceBookingStatus.PENDING_HOST_CONFIRMATION,
        label: 'APP.SPACES.BOOKINGS.STATUS.PENDING_HOST_CONFIRMATION',
      },
      {
        value: SpaceBookingStatus.PENDING_REQUEST,
        label: 'APP.SPACES.BOOKINGS.STATUS.PENDING_REQUEST',
      },
      {
        value: SpaceBookingStatus.CONFIRMED,
        label: 'APP.SPACES.BOOKINGS.STATUS.CONFIRMED',
      },
      {
        value: SpaceBookingStatus.DECLINED_HOST,
        label: 'APP.SPACES.BOOKINGS.STATUS.DECLINED_HOST',
      },
      {
        value: SpaceBookingStatus.DECLINED_OWNER,
        label: 'APP.SPACES.BOOKINGS.STATUS.DECLINED_OWNER',
      },
      {
        value: SpaceBookingStatus.CANCELLED_OWNER,
        label: 'APP.SPACES.BOOKINGS.STATUS.CANCELLED_OWNER',
      },
    ];
  }
}
