export enum SpaceSlotType {
  BOOKING = 'BOOKING',
  AVAILABILITY = 'AVAILABILITY',
  BLOCKER = 'BLOCKER',

  // fictional slot
  MAINTENANCE = 'MAINTENANCE',
}

export namespace SpaceSlotType {
  export function getAll(): SpaceSlotType[] {
    return [
      SpaceSlotType.BOOKING,
      SpaceSlotType.AVAILABILITY,
      SpaceSlotType.BLOCKER,
    ];
  }

  export function getDisplayName(spaceSlotType?: SpaceSlotType): string {
    switch (spaceSlotType) {
      case SpaceSlotType.BOOKING:
        return 'APP.SPACES.BOOKINGS.SLOT_TYPE.BOOKING';
      case SpaceSlotType.AVAILABILITY:
        return 'APP.SPACES.BOOKINGS.SLOT_TYPE.AVAILABILITY';
      case SpaceSlotType.BLOCKER:
        return 'APP.SPACES.BOOKINGS.SLOT_TYPE.BLOCKER';
      case SpaceSlotType.MAINTENANCE:
        return 'APP.SPACES.BOOKINGS.SLOT_TYPE.MAINTENANCE';
      default:
        return '';
    }
  }
}
