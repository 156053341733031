import { ClassData } from '@models/class-data';
import { SpaceAccess } from '@models/spaces/enums/space-access.model';
import { SpaceAvailabilityOpeningDays } from '@models/spaces/enums/space-availability-opening-days.enum';
import { User } from '@models/users/user.model';
import { SpaceType } from './space-type.model';
import { SpaceStatus } from './enums/space-status.enum';
import { SpaceCancellationPolicy } from './enums/space-cancellation-policy.enum';
import { SpaceCustomCancellationPolicy } from './space-custom-cancellation-policy.model';
import { Address } from './address.model';
import { SpaceImage } from './space-image.model';
import { SpaceDiscount } from './space-discount.model';
import { SpaceFeature } from './space-feature.model';
import { Brand } from '@models/brands/brand.model';
import { SpaceTag } from './space-tag.model';
import { Helpers } from '@utils/helpers';
import { AppRoutes } from 'app/routes';
import { environment as env } from '@environments/environment';
import { SpaceDiscountType } from './enums/space-discount-type.enum';
import { SizeMeasurementUnit } from '@models/shared/enums/size-measurement-unit.enum';
import { SpaceStyle } from '@models/spaces/space-style.model';
import { SpaceOpeningHour } from '@models/spaces/space-opening-hour.model';

export class Space {
  id: number;
  createdAt?: Date;

  parentSpaceId?: number;
  parentSpace?: Space;

  ownerId: number;
  owner?: User;

  typeId: number;
  type?: SpaceType;

  status: SpaceStatus;

  uri: string;

  name: string;
  description: string;

  mainImageUrl: string;
  logoUrl: string;

  size: number;
  sizeMeasurementUnit: SizeMeasurementUnit;
  capacity: number;

  // Pricing
  hourlyRateWeekdayPriceInCents: number;
  hourlyRateWeekendPriceInCents: number;
  dailyRateWeekdayPriceInCents: number;
  dailyRateWeekendPriceInCents: number;

  minimumDurationInHours: number;
  fullDayDurationInHours: number;

  cancellationPolicy: SpaceCancellationPolicy;
  customCancellationPolicies?: SpaceCustomCancellationPolicy[];

  customSlotsSupported: boolean;
  useInstantBooking: boolean;

  addressId?: number;
  address?: Address;
  access?: SpaceAccess;

  images: SpaceImage[];
  features: SpaceFeature[];
  openingHours: SpaceOpeningHour[];
  openingDays: SpaceAvailabilityOpeningDays;

  discounts: SpaceDiscount[];

  brandId?: number;
  brand?: Brand;

  tags: SpaceTag[];

  styleId?: number;
  style?: SpaceStyle;

  constructor(json: ClassData<Space>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    if (json.parentSpaceId) {
      this.parentSpaceId = +json.parentSpaceId;
    }
    if (json.parentSpace) {
      this.parentSpace = new Space(json.parentSpace);
    }

    this.ownerId = +json.ownerId;
    if (json.owner) {
      this.owner = new User(json.owner);
    }
    this.access = json.access;
    this.typeId = +json.typeId;
    if (json.type) {
      this.type = new SpaceType(json.type);
    }
    this.openingDays = json.openingDays;

    this.status = json.status;

    this.uri = json.uri;

    this.name = json.name;
    this.description = json.description;

    this.mainImageUrl = json.mainImageUrl;
    this.logoUrl = json.logoUrl;

    this.size = +json.size;
    this.sizeMeasurementUnit = json.sizeMeasurementUnit;
    this.capacity = +json.capacity;

    this.hourlyRateWeekdayPriceInCents = +json.hourlyRateWeekdayPriceInCents;
    this.hourlyRateWeekendPriceInCents = +json.hourlyRateWeekendPriceInCents;
    this.dailyRateWeekdayPriceInCents = +json.dailyRateWeekdayPriceInCents;
    this.dailyRateWeekendPriceInCents = +json.dailyRateWeekendPriceInCents;

    this.minimumDurationInHours = +json.minimumDurationInHours;
    this.fullDayDurationInHours = +json.fullDayDurationInHours;

    this.cancellationPolicy = json.cancellationPolicy;
    if (json.customCancellationPolicies) {
      this.customCancellationPolicies = json.customCancellationPolicies.map(
        (it) => new SpaceCustomCancellationPolicy(it),
      );
    }

    this.customSlotsSupported = json.customSlotsSupported;
    this.useInstantBooking = json.useInstantBooking;

    if (json.addressId) {
      this.addressId = json.addressId;
    }
    if (json.address) {
      this.address = new Address(json.address);
    }

    this.images = json.images
      ? json.images.map((it) => new SpaceImage(it))
      : [];
    this.features = json.features
      ? json.features.map((it) => new SpaceFeature(it))
      : [];

    this.openingHours = json.openingHours
      ? json.openingHours.map((it) => new SpaceOpeningHour(it))
      : [];

    this.discounts = json.discounts
      ? json.discounts.map((it) => new SpaceDiscount(it))
      : [];

    if (json.brandId) {
      this.brandId = json.brandId;
    }
    if (json.brand) {
      this.brand = new Brand(json.brand);
    }
    this.styleId = json.styleId;

    if (json.style) {
      this.style = new SpaceStyle(json.style);
    }

    this.tags = json.tags ? json.tags.map((it) => new SpaceTag(it)) : [];
  }

  getEncodedMainImage(): string {
    let backgroundImage = '';
    if (!!this.mainImageUrl && this.mainImageUrl !== '') {
      backgroundImage = this.mainImageUrl;
    }

    if (
      backgroundImage.includes('unsplash') ||
      backgroundImage.includes('giphy')
    ) {
      return encodeURI(backgroundImage);
    }

    return Helpers.encodeUrl(backgroundImage);
  }

  isMainImageImage(): boolean {
    return (
      !!this.mainImageUrl &&
      !this.mainImageUrl.includes('.mp4') &&
      !this.mainImageUrl.includes('.webm')
    );
  }

  isMainImageVideo(): boolean {
    return (
      !!this.mainImageUrl &&
      (!!this.mainImageUrl.includes('.mp4') ||
        !!this.mainImageUrl.includes('.webm'))
    );
  }

  getLink(): string {
    return `${env.website}/${AppRoutes.Customer.Spaces.spaces}/${this.uri}`;
  }

  getHourlyRateWeekdayPriceFormatted(): string {
    if (this.hourlyRateWeekdayPriceInCents === undefined) {
      return '';
    }
    return (this.hourlyRateWeekdayPriceInCents / 100.0).toFixed(2);
  }

  getHourlyRateWeekendPriceFormatted(): string {
    if (this.hourlyRateWeekendPriceInCents === undefined) {
      return '';
    }
    return (this.hourlyRateWeekendPriceInCents / 100.0).toFixed(2);
  }

  getDailyRateWeekdayPriceFormatted(): string {
    if (this.dailyRateWeekdayPriceInCents === undefined) {
      return '';
    }
    return (this.dailyRateWeekdayPriceInCents / 100.0).toFixed(2);
  }

  getDailyRateWeekendPriceFormatted(): string {
    if (this.dailyRateWeekendPriceInCents === undefined) {
      return '';
    }
    return (this.dailyRateWeekendPriceInCents / 100.0).toFixed(2);
  }

  getDiscountTypes(): SpaceDiscountType[] {
    if (!this.discounts || this.discounts.length === 0) {
      return [];
    }

    return this.discounts.map((discount) => discount.type);
  }

  hasAddress(): boolean {
    return (
      !!this.address &&
      (this.address.isFilled() || this.address.isPartiallyFilled())
    );
  }

  getAddressDisplayName(): string {
    if (!this.address) {
      return '-';
    }
    return this.address.toStringWithName();
  }

  getAddressWithPostalCodeDisplayName(): string {
    if (!this.address) {
      return '-';
    }
    return this.address.toString();
  }

  getAddressDisplayNamePrimary(): string {
    if (!this.address) {
      return '-';
    }
    return this.address.toDisplayForEventPrimary();
  }

  getAddressDisplayNameSecondary(): string {
    if (!this.address) {
      return '-';
    }
    return this.address.toDisplayForEventSecondary();
  }

  getAddressDisplayNameCommunity(): string {
    if (!this.address) {
      return '-';
    }
    return this.address.toDisplayForEventCommunityCard();
  }

  getGoogleMapsLink() {
    if (!this.address) {
      return '';
    }

    if (
      this.address.placeId &&
      this.address.placeId !== '' &&
      this.address.placeId.startsWith('Ch')
    ) {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        this.address.toStringWithName(),
      )}&query_place_id=${this.address.placeId}`;
    } else if (this.address.lat && this.address.lng) {
      return `https://www.google.com/maps/search/?api=1&query=${this.address.lat},${this.address.lng}`;
    } else {
      return '';
    }
  }

  isUserOwner(userId: number) {
    return this.ownerId === userId;
  }

  isPublished(): boolean {
    return this.status === SpaceStatus.ACTIVE;
  }

  isSubSpace(): boolean {
    return !!this.parentSpaceId;
  }

  getParentSpaceName(): string {
    if (!this.parentSpace) {
      return '';
    }

    return this.parentSpace.name;
  }
}
