import { ClassData } from '@models/class-data';
import { SpaceBookingStatus } from './enums/space-booking-status.enum';
import { SpaceBookingPaymentStatus } from './enums/space-booking-payment-status.enum';
import { CapacityVisibility } from './enums/capacity-visiblity.enum';
import { SpaceBookingInvitationManagement } from './enums/space-booking-invitation-management.enum';
import { Space } from './space.model';
import { SpaceSlot } from './space-slot.model';
import { SpaceBookingTicket } from './space-booking-ticket.model';
import { TicketLayout } from '@models/tickets/ticket-layout.model';
import { DateUtils } from '@utils/date-utils';
import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

export class SpaceBooking {
  id: number;

  spaceId: number;
  space?: Space;

  slotId: number;
  slot?: SpaceSlot;

  uuid: string;

  status: SpaceBookingStatus;
  comment?: string; // comment for decline/cancel

  eventId?: number;

  hostEmail?: string;

  name: string;

  bookingNumber: string;

  totalPriceInCents: number;
  paymentStatus: SpaceBookingPaymentStatus;

  capacityVisibility: CapacityVisibility;

  invitationManagement: SpaceBookingInvitationManagement;

  sendTicketsToHost?: boolean;
  sendTicketsToHostEmail?: string;

  tickets?: SpaceBookingTicket[];

  layoutId?: number;
  layout?: TicketLayout;

  constructor(json: ClassData<SpaceBooking>) {
    this.id = +json.id;

    this.spaceId = +json.spaceId;
    if (json.space) {
      this.space = new Space(json.space);
    }

    this.slotId = +json.slotId;
    if (json.slot) {
      this.slot = new SpaceSlot(json.slot);
    }

    this.uuid = json.uuid;

    this.status = json.status;
    this.comment = json.comment;

    if (json.eventId) {
      this.eventId = json.eventId;
    }

    this.hostEmail = json.hostEmail;

    this.name = json.name;

    this.bookingNumber = json.bookingNumber;

    this.totalPriceInCents = +json.totalPriceInCents;
    this.paymentStatus = json.paymentStatus;

    this.capacityVisibility = json.capacityVisibility;

    this.invitationManagement = json.invitationManagement;

    if (json.sendTicketsToHost !== undefined) {
      this.sendTicketsToHost = json.sendTicketsToHost;
    }
    this.sendTicketsToHostEmail = json.sendTicketsToHostEmail;

    if (json.tickets) {
      this.tickets = json.tickets.map(
        (ticket) => new SpaceBookingTicket(ticket),
      );
    }

    if (json.layoutId) {
      this.layoutId = +json.layoutId;
    }
    if (json.layout) {
      this.layout = new TicketLayout(json.layout);
    }
  }

  getDatePrimary(): string {
    if (!this.slot) {
      return '-';
    }

    const startDate = moment(this.slot.startDate).format('DD.MM.yyyy');
    const startTime = moment(this.slot.startDate).format('HH:mm');
    const endTime = this.slot.endDate
      ? moment(this.slot.endDate).format('HH:mm')
      : '';

    if (this.slot.allDay) {
      let displayDate = startDate;
      if (this.slot.endDate && !this.endsOnSameDate()) {
        const endDate = moment(this.slot.endDate).format('DD.MM.yyyy');
        displayDate = `${displayDate} - ${endDate}`;
      }

      return displayDate;
    }

    let displayDate = `${startDate} ${startTime}`;

    if (this.endsOnSameDate()) {
      displayDate = `${displayDate} - ${endTime}`;
    } else if (this.slot.endDate && !this.endsOnSameDate()) {
      const endDate = moment(this.slot.endDate).format('DD.MM.yyyy');
      displayDate = `${displayDate} - ${endDate} ${endTime}`;
    }

    return displayDate;
  }

  getDateSecondary(translateService: TranslateService): string {
    if (!this.slot) {
      return '-';
    }

    return DateUtils.getLocalizedDurationInfo(
      this.slot.startDate,
      this.slot.endDate,
      translateService,
      this.slot.allDay,
    );
  }

  endsOnSameDate(): boolean {
    if (!this.slot) {
      return false;
    }

    return moment(this.slot.startDate).isSame(this.slot.endDate, 'day');
  }

  isPendingHostConfirmation(): boolean {
    return this.status === SpaceBookingStatus.PENDING_HOST_CONFIRMATION;
  }

  isConfirmed(): boolean {
    return this.status === SpaceBookingStatus.CONFIRMED;
  }

  isDeclinedByHost(): boolean {
    return this.status === SpaceBookingStatus.DECLINED_HOST;
  }

  isDeclinedOrCancelled(): boolean {
    return [
      SpaceBookingStatus.DECLINED_HOST,
      SpaceBookingStatus.DECLINED_OWNER,
      SpaceBookingStatus.CANCELLED_OWNER,
    ].includes(this.status);
  }

  isDraft(): boolean {
    return this.status === SpaceBookingStatus.DRAFT;
  }

  getBookingStatusClass(): string {
    switch (this.status) {
      case SpaceBookingStatus.PENDING_HOST_CONFIRMATION:
      case SpaceBookingStatus.PENDING_REQUEST:
        return 'extra-orange';
      case SpaceBookingStatus.CONFIRMED:
        return 'extra-green';
      case SpaceBookingStatus.DECLINED_HOST:
      case SpaceBookingStatus.DECLINED_OWNER:
      case SpaceBookingStatus.CANCELLED_OWNER:
        return 'extra-red';
      default:
        return '';
    }
  }
}
