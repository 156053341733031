import { ClassData } from '@models/class-data';

export class SpaceCustomCancellationPolicy {
  id: number;

  numDaysBeforeEvent: number;
  refundPercentage: number;

  constructor(json: ClassData<SpaceCustomCancellationPolicy>) {
    this.id = +json.id;

    this.numDaysBeforeEvent = +json.numDaysBeforeEvent;
    this.refundPercentage = +json.refundPercentage;
  }
}
