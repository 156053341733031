import { ClassData } from '@models/class-data';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';

export class SpaceStyle {
  id: number;

  backgroundColor: string;
  titleColor: string;
  fontColor: string;
  titleFont: string;
  titleFontWeight: string;
  bodyFont: string;
  bodyFontWeight: string;
  backgroundId?: number;
  background?: EventTemplateAsset;
  customBackgroundUrl?: string;

  constructor(json: ClassData<SpaceStyle>) {
    this.id = +json.id;
    this.backgroundColor = json.backgroundColor;
    this.titleColor = json.titleColor;
    this.fontColor = json.fontColor;
    this.titleFont = json.titleFont;
    this.titleFontWeight = json.titleFontWeight;
    this.bodyFont = json.bodyFont;
    this.bodyFontWeight = json.bodyFontWeight;
    this.backgroundId = json.backgroundId;
    if (json.background) {
      this.background = new EventTemplateAsset(json.background);
    }
    this.customBackgroundUrl = json.customBackgroundUrl;
  }
}
