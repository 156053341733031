import { ClassData } from '@models/class-data';

export class SpaceType {
  id: number;

  icon: string;
  name: string;

  constructor(json: ClassData<SpaceType>) {
    this.id = +json.id;

    this.icon = json.icon;
    this.name = json.name;
  }

  getTitle(): string {
    return `APP.SPACES.TYPES.${this.name}`;
  }

  getTitleSingular(): string {
    return `APP.SPACES.TYPES.SINGULAR.${this.name}`;
  }
}
