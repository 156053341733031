import { ClassData } from '@models/class-data';
import { SpaceBooking } from './space-booking.model';
import { SpaceBookingTicketHoldeRole } from './enums/space-booking-ticket-holder-role.enum';
import { TicketSeatAssignment } from '@models/tickets/ticket-seat-assignment.model';

export class SpaceBookingTicket {
  id: number;
  createdAt?: Date;

  uuid: string;

  code: string;

  holderName: string;
  holderEmail: string;
  holderRole: SpaceBookingTicketHoldeRole;

  spaceBookingId: number;
  spaceBooking?: SpaceBooking;

  seatAssignmentId?: number;
  seatAssignment?: TicketSeatAssignment;

  isUsed: boolean;
  usedDate?: Date;

  passcreatorUrl?: string;

  constructor(json: ClassData<SpaceBookingTicket>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.uuid = json.uuid;
    this.code = json.code;

    this.holderName = json.holderName;
    this.holderEmail = json.holderEmail;
    this.holderRole = json.holderRole;

    this.spaceBookingId = +json.spaceBookingId;
    if (json.spaceBooking) {
      this.spaceBooking = new SpaceBooking(json.spaceBooking);
    }

    if (json.seatAssignmentId) {
      this.seatAssignmentId = +json.seatAssignmentId;
    }
    if (json.seatAssignment) {
      this.seatAssignment = new TicketSeatAssignment(json.seatAssignment);
    }

    this.isUsed = json.isUsed;
    if (json.usedDate) {
      this.usedDate = new Date(json.usedDate);
    }

    this.passcreatorUrl = json.passcreatorUrl;
  }

  getSeatingDisplay() {
    if (!this.seatAssignment) {
      return '-';
    }

    return this.seatAssignment.toString();
  }
}
