import { ClassData } from '@models/class-data';

export class SpaceImage {
  id: number;

  url: string;

  order: number;

  constructor(json: ClassData<SpaceImage>) {
    this.id = +json.id;

    this.url = json.url;

    this.order = +json.order;
  }
}
