import { ClassData } from '@models/class-data';
import { Weekday } from '@models/shared/enums/weekday.enum';

export class SpaceOpeningHour {
  id: number;

  spaceId: number;

  day: Weekday;

  fromTime: string;
  toTime: string;

  constructor(json: ClassData<SpaceOpeningHour>) {
    this.id = +json.id;

    this.spaceId = +json.spaceId;

    this.day = json.day;

    this.fromTime = json.fromTime;
    this.toTime = json.toTime;
  }
}
