export enum SpaceStatus {
  DRAFT_CREATION = 'DRAFT_CREATION',
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
}
export namespace SpaceStatus {
  export function getColor(status: SpaceStatus): string {
    switch (status) {
      case SpaceStatus.ACTIVE:
        return '#33b977';
      default:
        return '#d8d8d8';
    }
  }
}
