import { ClassData } from '@models/class-data';

export class SpaceTag {
  id: number;

  name: string;

  constructor(json: ClassData<SpaceTag>) {
    this.id = +json.id;

    this.name = json.name;
  }
}
